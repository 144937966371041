<template>
  <div class="main_container">
        <div class="paper_main">
            <div class="paper_top">
                <div class="paper_top_red">摸鱼办宣传文件</div>
                <div class="paper_top_orig">宣传组发 [{{nowYear}}] {{fileNum}} 号文件</div>
            </div>
            <div class="paper_commont">
                <div class="paper_commont_title">摸鱼办通知</div>
                <div class="paper_commont_core">
                    全体摸鱼同志：
                    <p class="paper_commont_core_text">
                        即使今天是开工第 {{wordDays}} 天 也一定不要忘记摸鱼哦！摸鱼才是自己赚的钱，努力工作得到的是报酬！有事没事起身去茶水间，去厕所，去廊道走走，别总在工位上坐着，钱是老板的，但健康是自己的。
                    </p>
                    <p>距离 [双休] <span v-if="doubleRelax > 0">还有：{{doubleRelax}} 天</span><span v-else>明天就是了</span></p>
                    <!-- <p>距离 [单休] 还有：{{singleRelax}} 天</p> -->
                    <p v-show="freeallen >= 0">距离 [Allen解封] <span v-if="freeallen > 0">还有：{{freeallen}} 天</span><span v-else>明天就是了</span>{{freeallen}}</p>
                    <p v-show="qingming >= 0">距离 [清明节] <span v-if="qingming > 0">还有：{{qingming}} 天</span><span v-else>明天就是了</span></p>
                    <p v-show="wuyi >= 0">距离 [五一节] <span v-if="wuyi > 0">还有：{{wuyi}} 天</span><span v-else>明天就是了</span></p>
                    <p v-show="duanwu >= 0">距离 [端午节] <span v-if="duanwu > 0">还有：{{duanwu}} 天</span><span v-else>明天就是了</span></p>
                    <p v-show="zhongqiu >= 0">距离 [中秋节] <span v-if="zhongqiu > 0">还有：{{zhongqiu}} 天</span><span v-else>明天就是了</span></p>
                    <p v-show="guoqin >= 0">距离 [国庆节] <span v-if="guoqin > 0">还有：{{guoqin}} 天</span><span v-else>明天就是了</span></p>
                    <p v-show="yuandan >= 0">距离 [元旦节] <span v-if="yuandan > 0">还有：{{yuandan}} 天</span><span v-else>明天就是了</span></p>
                    <p v-show="chunjie >= 0">距离 [春节] <span v-if="chunjie > 0">还有：{{chunjie}} 天</span><span v-else>明天就是了</span></p>
                </div>
            </div>
            <div class="paper_bottom">
                <p>摸鱼办宣传组</p>
                <p>{{nowDate}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {},
  data() {
    return {
        nowYear: 2022,
        fileNum: 223,
        workTime: 1644163200000,
        yuandanTime: 1640966400000,
        nowDate: "2022年03月19日",
        wordDays: 33,
        doubleRelax: 10,
        singleRelax: 3,
        freeallen: 20,
        qingming: 10,
        wuyi: 20,
        duanwu: 23,
        zhongqiu: 223,
        guoqin: 224,
        yuandan: 2332,
        chunjie: 233,

        holidayTime: {
            freeallen: 1649952000000,
            qingming: 1648915200000,
            wuyi: 1651248000000,
            duanwu: 1654185600000,
            zhongqiu: 1662739200000,
            guoqin: 1664553600000,
            yuandan: 1672502400000,
            chunjie: 1674316800000
        }
    }
  },
  mounted: function() {
      this.setData();
  },
  methods: {
      setData() {
        let nowtime = new Date().getTime();

        let ms = nowtime - this.workTime;
        this.wordDays = parseInt(ms/(1000*60*60*24));
         
        this.nowDate = this.getCalaDate(nowtime);
        this.getHolidayNum();
      },
      getCalaDate(timedata) {
          var sdata = new Date(timedata);
          let year = sdata.getFullYear();
          let month = (sdata.getMonth()+1 < 10 ? '0'+(sdata.getMonth()+1) : sdata.getMonth()+1);
          let day = sdata.getDate();
          day = day < 10 ? '0' + day : day;
          let week = sdata.getDay();

          this.doubleRelax = 5 - week;
          this.singleRelax = 6 - week;

          this.nowYear = year;
          this.fileNum = this.getDays(sdata);

        return year + " 年 " + month + " 月 " + day + " 日";
      },
      getDays(timedata){
          let times = timedata - this.yuandanTime;
          return parseInt(times/(1000*60*60*24));
      },
      getHolidayNum() {
          let ssdata = new Date().getTime();

          console.log(ssdata);
          let holiday = this.holidayTime;
          for(let key in holiday){
              let holidayT = new Date(holiday[key]);
              let daysNum = (holidayT - ssdata)/(1000*60*60*24)
              let days = daysNum > 0 ? Math.trunc(daysNum) : -99;
              console.log(days);
              this[key] = days;
          }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: #fafafa;
    }
    
    .paper_main {
        width: 400px;
        height: 650px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: white;
        box-shadow: 0 2px 15px #f7f7f7;
        position: relative;
    }
    
    .paper_top {
        width: 100%;
        border-bottom: 2px solid red;
        padding-bottom: 0.5em;
        margin-top: 1em;
    }
    
    .paper_top_red {
        color: red;
        text-align: center;
        font-size: 1.7em;
        margin-block: 0.5em;
        font-weight: 600;
    }
    
    .paper_top_orig {
        font-size: 0.5em;
        text-align: center;
    }
    
    .paper_commont {
        padding: 1em;
        padding-inline: 1.5em;
    }
    
    .paper_commont_title {
        margin-block: 1em;
        text-align: center;
        font-size: 1.1em;
        font-weight: 800;
    }
    
    .paper_commont_core {
        font-size: 0.8em;
    }
    
    .paper_commont_core p {
        text-indent: 2em;
        /* line-height: 1.8em; */
    }
    
    .paper_commont_core_text {
        text-indent: 2em;
        line-height: 1.8em;
    }
    
    .cny_bottom {
        position: fixed;
        right: 1em;
        bottom: 1em;
        color: #cccccc;
    }
    .paper_bottom {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        /* border-top: 1px solid #888888; */
        font-size: 0.8em;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        padding-bottom: 3em;
    }
    .paper_bottom p {
        margin-block: 0.3em;
        margin-right: 2em;
        width: 120px;
        text-align: center;
    }
</style>
